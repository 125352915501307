<template>
    <v-container>
        <v-card>
            <v-card-title>Subir documento/imagen</v-card-title>
            <v-card-text>
                    <v-form ref="form" v-model="validform">
                        <v-row>
                            <v-col cols="12">
                                <v-file-input
                                accept="image/png, image/jpeg, application/pdf, video/quicktime, video/mp4, video/x-msvideo"
                                label="Imagen/documento a subir (png, jpg, pdf, mpg, mov, wmv, avi)"
                                placeholder="Seleccione los archivos que desea subir"
                                truncate-length="30"
                                counter
                                multiple
                                small-chips
                                :rules="fileRules"
                                prepend-icon="mdi-paperclip"
                                v-model="files"
                                @click:clear="files = undefined"></v-file-input>
                             </v-col>
                        </v-row>
                        <v-row>
                        <v-col cols="12">
                        <v-select
                          v-if="catalogo && catalogo.length > 0"
                          v-model="tipoDocumento"
                          :items="catalogo"
                          class="mt-2"
                          item-text="descripcion"
                          item-value="id"
                          label="¿Qué tipo de documento/imagen es?"
                          single-line
                          return-object
                          hint="Tipo de documento"
                          :persistent-hint="select && select.id ? true : false"
                          dense
                          :menu-props="{ top: false, offsetY: true }"
                        ></v-select>
                        </v-col>
                        </v-row>

                        <v-row>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                            <v-textarea v-model.trim="descripcion"
                                                    label="Descripción del documento/imagen"
                                                    :rows="3"
                                                    outlined
                                                    :rules="rules"
                                                    required
                                                    counter="256"
                                                ></v-textarea>
                            </v-col>
                        </v-row>

                        <v-row v-if="numFileUpload > 0">
                            <v-col cols="12">

                            <strong>Subiendo {{ numFileUpload }} de {{ files.length }}</strong>
                            </v-col>
                        </v-row>

                        <v-row v-if="numFileUpload > 0 && uploadProgressPercentage && uploadProgressPercentage > 0">
                            <v-col cols="12">
                            <v-progress-linear color="cyan"
                                v-model="uploadProgressPercentage"
                                height="25">
                                <strong>{{ Math.ceil(uploadProgressPercentage) }}%</strong>
                            </v-progress-linear>
                            </v-col>

                        </v-row>





                    </v-form>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="$emit('cancel')">Cancelar</v-btn>
                <v-btn @click="guardar" :disabled="!validform" color="success">
                    Guardar
                </v-btn>



            </v-card-actions>
        </v-card>

        <v-snackbar
                        v-if="snackbar && snackbar != null"
                        v-model="snackbar.show"
                        :timeout="sbtimeout"
                        top :color="snackbar.color"
                      >
                        {{ snackbar.msg }}
                        <v-btn icon color="teal lighten-2" @click="snackbar.show = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
        </v-snackbar>


    </v-container>
</template>

<script>
import AdjuntoService from "@/services/adjunto.service.js";
import axios from 'axios'

export default {
    name: 'FileUpload',
    data: function(){
        return {
            validform: false,
            sbtimeout: 6000,
            snackbar:{ show: false, msg: "", color: "success"},
            descripcion:"",
            tipoDocumento:{},
            files:[],
            catalogo:[],
            fileRules:[
                            (files) =>
                              !files ||
                              !files.some((file) => file.size > 30e6) ||
                              "Peso máximo permitido 30 MB!",
                            (files) => !files || !files.length == 0 || "Campo obligatorio",
                          ],
            show:false,
            params:{},
            maxLength:256,
            rules: [
                  (v) => (!!v && v.length > 0) || "Descripción requerida",
                  (v) => (!!v && v.length < 256) || "Máximo 256 caracteres",
                ],
            uploadProgressPercentage: 0,
            numFileUpload: -1,
            select: {}
        };
    },
    props: {
            p: { required: true },
            t: {default:0}
    },
    computed:{
    },
    watch:{
        descripcion:function(){
            if(this.descripcion && this.descripcion.length > this.maxLength)
            {
                this.$nextTick(() => {
                    this.descripcion =  this.descripcion.substring(0, this.maxLength);
                });
            }
        }
    },
    methods:{
        selectFile(file) {
              this.uploadProgressPercentage = 0;
              this.archivoSeleccionado = file;

        },
        message:function(show, msg, color)
        {
            this.snackbar.show= show;
            this.snackbar.msg = msg;
            this.snackbar.color = color;

        },
        onUploadProgress(progressEvent) {
            this.uploadProgressPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (Math.ceil(this.uploadProgressPercentage) == 100) {
                this.uploadProgressPercentage = 99;
            }
        },
        obtHoy: function () {
            let dt = new Date();
            return `${dt.getDate().toString().padStart(2, '0')}/${(dt.getMonth()+1).toString().padStart(2, '0')}/${dt.getFullYear().toString().padStart(4, '0')} ${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}`;
        },
        guardar:function(){
            if (this.$refs.form.validate()) {
                let obj = this.tipoDocumento && this.tipoDocumento.id ? this.tipoDocumento : { id: 0 };
                this.params.descripcion = this.descripcion;
                this.params.tipoDocumento = obj.id;
                this.params.descripcionTipoDocumento = obj.descripcion;
                this.upload(0);
            }
        },
        upload: function(idx) {
            var data = new FormData();
            let nombre = this.files[idx].name;

            data.append("nombre", nombre);
            data.append("file", this.files[idx]);
            data.append("tipoDocumento", this.params.tipoDocumento);
            data.append("descripcion", this.params.descripcion);
            data.append("pacienteId", this.p);

            this.numFileUpload = idx + 1;
            let contentType = this.files[idx].type;
            let type = this.obtType(contentType);

            //console.log('guardando....'+idx);
            axios.post("/api/adjuntos/upload", data,{
              headers: { "Content-Type": "multipart/form-data" },
              onUploadProgress: function (progressEvent) {
                this.onUploadProgress(progressEvent);
              }.bind(this),

            }).then(function(response){

              if (response && response.data.length > 30) {

                  let url = this.obtUrl(idx);
                  let nt = this.t;

                  var obj = {
                    identificador: response.data,
                    nombre: nombre,
                    descripcion: this.params.descripcion,
                    src: url,
                    url: url,
                    contentType: contentType, //Requerido para simular que ya fue descargado desde el server.
                    tipo: type,
                    tipoDocumento: this.params.descripcionTipoDocumento,
                    fechaHora: this.obtHoy(),
                    t: nt,
                  };
                  this.$emit('add', obj);
                  //this.message("Archivo guardado [ " + nombre + "]", "success");
              } else
              {
                this.message("El archivo [" + nombre + "] no pudo guardarse","error");
              }

            }.bind(this)

            )
        .catch(
          function (error) {
            let msg =
              error.response && error.response.headers && error.response.headers["err-msg"]
                ? error.response.headers["err-msg"]
                : error.message
                ? error.message
                : error;

            this.message(msg, "error");

          }.bind(this)
        )
        .then(
          function () {
            if (idx < this.files.length - 1) {
              this.uploadProgressPercentage = undefined;
              setTimeout(() => {
                this.upload(idx + 1);
              }, 250);
            } else {
              this.numFileUpload = -1;
              this.uploadProgressPercentage = undefined;
              this.descripcion = "";
              this.files = [];
              this.$emit('cancel');

            }
          }.bind(this));

        },
        obtUrl(idx) {
            return URL.createObjectURL(this.files[idx]);
        },
        obtType(contentType) {
          //=documento, imagen, video
          return contentType.indexOf("image") > -1
            ? "imagen"
            : contentType.indexOf("pdf") > -1
            ? "documento"
            : "video";
        }
    },
    beforeMount(){
        AdjuntoService.getCatalogoTipoDocumento().then((data) =>{
            if(data){
                this.catalogo = data;
            }
        });



    }
}
</script>